/* ==========================================================================
    [2. RESET STYLES]
========================================================================== */

body {
  overflow-x: hidden;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}

a {
  transition: all 0.3s ease-in;

  &,
  &:focus,
  &:active,
  &:hover {
    outline: 0 !important;
    text-decoration: $link-hover-decoration;
  }
}

hr {
  display: block;
  height: 1px;
  border: 0;
  margin: 1em 0;
  padding: 0;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

.wthn-bg-blue{
  background-color: #63b5e6;
}
.wthn-bg-red{
  background-color: #e84d14;
}
.wthn-bg-orange{
  background-color: #f3941a;
}
.wthn-bg-green{
  background-color: #93c11f;
}

.wthn-btn{
  color: whitesmoke;
  font-family: "Lato", sans-serif;
}

.wthn-btn-animate{
  display: inline-block;
  position: relative;
  overflow: hidden;
}
.wthn-btn-animate::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #63b5e6;
        border-radius: 10rem;
        z-index: -2;
    }
    .wthn-btn-animate::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: darken(#63b5e6, 15%);
        transition: all .3s;
        border-radius: 10rem;
        z-index: -1;
    }
    .wthn-btn-animate::hover {
        color: #fff;
        &:before {
            width: 100%;
        }
    }

.wthn-underlined {
  position: relative;
  margin-right: 1rem;
}
.wthn-underlined:after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  height: 7px;
  width: 100%;
  border: solid 2px #cb1829;
  border-color: #cb1829 transparent transparent transparent;
  border-radius: 50%;
}

//Experimenting with a word highlighting function, currently works but is not responsive to screen size changes
/* .wthn-highlight:before {
  content: "";
  background: #7FDBFF;
  position: absolute;
  width: 21%;
  height: 5px;
  bottom: 0;
  left: 0;
  border-radius: 5px;
  transform: scaleX(0);
  animation: 1.4s forwards no-hover-v linear;
  animation-fill-mode: forwards;
  z-index: -1;
}
.wthn-highlight:hover:before,
.wthn-highlight:focus:before {
  animation: .5s forwards hover-v linear;
  animation-fill-mode: forwards;
}
@keyframes hover-v {
  0% {
      transform: scaleX(0);
      height: 5px;
     }
  45% {   
      transform: scaleX(1.05);
      height: 5px;
     }
  55% {height: 5px;}
  100% {
      transform: scaleX(1.05);
      height: 3.8rem;
     }
}
@keyframes no-hover-v {
  0% {
      transform: scaleX(1.05);
      height: 3.8rem;
     }
  45% {height: 5px;}
  55% {   
      transform: scaleX(1.05);
      height: 5px;
      opacity: 1;
     }
  
  100% {
      transform: scaleX(0);
      height: 5px;
      opacity: .02;
     }
} */