$rotate-md: -12deg;
$straight: 0deg;
$side: -90deg;
$mock-full-width: 100%;
$mock-xl-width: 829px;
$device-margin: 50px;

.lightweight-template .learn-more {
  margin-bottom: 18rem;
}

.isometric-mockups {
  pointer-events: none;
  position: absolute;
  display: flex;
  align-items: center;
  left: 59%;
  margin-left: 85px;
  transform: scale(.5) rotate($rotate-md) translateX(-50%);
  transform-origin: 0 20%;
  z-index: 3;
  top: 340px;

  > div {
    margin: 0 50px;
  }

  .ipad {
    max-width: 100%;
  }

  .phone-big {
    display: none;
  }

  .phone-small {
    order: -1;
    width: 225px;
  }

  .tablet {
    &.landscape {
      width: 512px;
      margin: 0;
    }

    &.portrait {
      width: 450px;
      margin-top: 0;
    }

    .button {
      transform: translate(-50%, 9px);
    }
  }

  @include media-breakpoint-up(sm) {
    margin-left: 0;
  }

  @include media-breakpoint-up(md) {
    top: 39px;
    flex-wrap: wrap;
    transform: rotate($straight);
    transform-origin: 100% 0;
    width: $mock-xl-width;
    margin-left: -10px;

    .tablet {
      margin: 0!important;
      max-width: 100%;
    }

    .lightweight-template .learn-more {
      margin-bottom: 0;
    }
  }

  @include media-breakpoint-up(md) {
    > div {
      margin: 0 auto;
    }

    .tablet {
      &.landscape {
        align-self: flex-end;
        margin-right: 50px;
        transform: translateX(0);
      }

      &.portrait {
        display: flex;
        width: 450px;
        margin-top: 50px !important;
      }
    }

    .phone-big {
      display: flex;
      width: 267px;
    }

    .phone-small {
      order: 0;
      align-self: flex-start;
      margin: 50px 50px 0 0;
    }
  }
}


.forthepeople-mockups {
  pointer-events: none;
  display: flex;
  align-items: center;
  transform: scale(.5) rotate($straight) translateX(-50%);
  transform-origin: 0 20%;
  z-index: 3;
  top: 340px;

  > div {
    margin: 0 50px;
  }

  .ipad {
    max-width: 100%;
  }

  .phone-big {
    display: none;
  }

  .phone-small {
    order: -1;
    width: 225px;
  }

  .tablet {
    &.landscape {
      width: 512px;
      margin: 0;
    }

    &.portrait {
      width: 450px;
      margin-top: 0;
    }
  }

  @include media-breakpoint-down(xs) {
    transform: none;
    transform-origin: top;
    align-items: end;
    display: inline-block;
    position: initial !important;
    .section-heading{
      font-size: 0.5rem;
    }
    > div {
      margin: 0;
    }
  }

  @include media-breakpoint-up(sm) {
  }

  @include media-breakpoint-up(md) {
    top: 40px;
    flex-wrap: wrap;
    transform: rotate($straight);
    transform-origin: 100% 0;
    width: $mock-full-width;

    .mockup-holder{
      max-width: none;
    }

    .tablet {
      margin: 0!important;
      max-width: 100%;
    }

    .lightweight-template .learn-more {
      margin-bottom: 0;
    }
  }

  @include media-breakpoint-up(md) {
    > div {
      margin: 0 auto;
    }

    .tablet {
      &.landscape {
        align-self: flex-end;
        transform: translateX(0);
      }

      &.portrait {
        display: flex;
        width: 450px;
        margin-top: 50px !important;
      }
    }

    .phone-big {
      display: flex;
      width: 267px;
    }

    .phone-small {
      order: 0;
      align-self: flex-start;
      // margin: 50px 64px 0 0;
    }
  }
}

.company-insights-mockup {
  pointer-events: none;
  // display: flex;
  align-items: center;
  transform: scale(.5) rotate($straight) translateX(-50%);
  transform-origin: 0 20%;
  z-index: 3;
  top: 500px;

  .portrait{
    display: none;
  }

  > div {
    margin: 0 50px;
  }

  .ipad {
    max-width: 100%;
  }

  .phone-big {
    display: none;
  }

  .phone-small {
    order: -1;
    width: 225px;
  }

  .tablet {
    &.landscape {
      width: 512px;
      margin: 0;
    }

    &.portrait {
      width: 450px;
      margin-top: 0;
    }
  }

  @include media-breakpoint-down(xs) {
    transform: scale(0.8) rotate(0deg) translateX(-20%);
    transform-origin: top;
    align-items: end;
    display: inline-block;
    position: initial !important;
    top: 0px;
    overflow: visible !important;
    padding-left: 40px;

    .landscape{
      display: none;
    }
    .portrait{
      display: flex;
    }
  }

  @include media-breakpoint-up(sm) {
  }

  @include media-breakpoint-up(md) {
    // top: 40px;
    flex-wrap: wrap;
    transform: rotate($side);
    transform-origin: 30% 0;
    width: $mock-full-width;

    .tablet {
      margin: 0!important;
      max-width: 100%;
    }

    .lightweight-template .learn-more {
      margin-bottom: 0;
    }
  }

  @include media-breakpoint-up(md) {
    > div {
      margin: 0 auto;
    }

    .tablet {
      &.landscape {
        align-self: flex-end;
        transform: translateX(0);
      }

      &.portrait {
        display: flex;
        width: 600px;
        margin-top: 50px !important;
      }
    }

    .phone-big {
      display: flex;
      width: 267px;
    }

    .phone-small {
      order: 0;
      align-self: flex-start;
      // margin: 50px 64px 0 0;
    }
  }
}

.mockup-holder{
  width: min-content;

  h5 {
    margin-top: 10px;;
  }
  /* p{
    padding-left: 5px;
    padding-right: 5px;
  } */
}

.for-the-people{
  @include media-breakpoint-down(xs) {
    .container{
      overflow: visible;
    }
  }
}
